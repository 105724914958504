<template>
  <div class='home'>
    <img class="header" src="../assets/header.png" />
    <div class="twitter-wrapper">
      <a class="twitter-timeline" href="https://twitter.com/13Days13Shorts?ref_src=twsrc%5Etfw">Tweets by 13Days13Shorts</a>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: function () {
    return {
    }
  }
}
</script>

<style scoped>
.graph {
  width: 800px;
  max-width: 100%;
  height: 600px;
  border: 1px solid #000;
}

.header {
  max-width: 80%;
  margin: auto;
}

.twitter-wrapper {
  max-width: 500px;
  max-height: 800px;
  overflow-y: scroll;
  margin: auto;
}
</style>
