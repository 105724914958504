import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VNetworkGraph from 'v-network-graph'
import 'v-network-graph/lib/style.css'
import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/lara-dark-teal/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(VNetworkGraph)
  .mount('#app')
